import React from "react";
import Sketch from "react-p5";
import p5Types from "p5";
import Boundary from "./raycasting/Boundary";
import Particle from "./raycasting/Particle";

interface ComponentProps {
  // no component props
}

const SketchCanvas: React.FC<ComponentProps> = (props: ComponentProps) => {

  let walls: Boundary[] = [];
  let particle: Particle;

  const setup = (p5: p5Types, canvasParentRef: Element) => {

    p5.createCanvas(400, 400).parent(canvasParentRef);

    for (let i = 0; i < 4; i++) {
      let x1 = p5.random(p5.width);
      let x2 = p5.random(p5.width);
      let y1 = p5.random(p5.height);
      let y2 = p5.random(p5.height);
      walls.push(new Boundary(p5, x1, y1, x2, y2));
    }

    particle = new Particle(p5);

  };

  const draw = (p5: p5Types) => {

    p5.background(0);

    for (let wall of walls) {
      wall.show();
    }

    particle.update(p5.mouseX, p5.mouseY);
    particle.show();
    particle.look(walls);

  };

  return <Sketch setup={setup} draw={draw} />;
};

export default SketchCanvas;
