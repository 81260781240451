import p5Types from "p5";
import Ray from "./Ray";

export default class Particle {

  private p5: p5Types;

  private pos: any;
  private rays: any[];

  constructor(p5: p5Types) {

    this.p5 = p5;
    this.pos = p5.createVector(p5.width / 2, p5.height / 2);
    this.rays = [];

    for (let a = 0; a < 360; a += 10) {
      this.rays.push(new Ray(p5, this.pos, p5.radians(a)));
    }

  }

  show() {

    this.p5.fill(255);
    this.p5.ellipse(this.pos.x, this.pos.y, 4);

    for (let ray of this.rays) {
      ray.show();
    }

  }

  update(x: number, y: number) {

    this.pos.set(x, y);

  }

  look(walls: any[]) {

    for (let ray of this.rays) {

      let closest = null;

      let record = Infinity;

      for (let wall of walls) {

        const point = ray.cast(wall);

        if (point) {

          const d = this.p5.dist(this.pos.x, this.pos.y, point.x, point.y);

          if (d < record) {
            record = d;
            closest = point;
          }

        }

      }

      if (closest) {

        this.p5.line(this.pos.x, this.pos.y, closest.x, closest.y);

      }

    }

  }

}
