import "./App.css";
import SketchCanvas from "./SketchCanvas";

function App() {
  return (<>
    <h1>learning raycasting</h1>
    <br />
    <SketchCanvas />
  </>);
}

export default App;
