import p5Types from "p5";

export default class Boundary {

  private p5: p5Types;

  public a: any;
  public b: any;

  constructor(p5: p5Types, x1: number, y1: number, x2: number, y2: number) {
    this.p5 = p5;
    this.a = p5.createVector(x1, y1);
    this.b = p5.createVector(x2, y2);
  }

  show() {
    this.p5.stroke(255);
    this.p5.line(this.a.x, this.a.y, this.b.x, this.b.y);
  }

}

